<template>
    <div class="top">
        <div class="top_t">
            <div class="top_le">
                <!-- <div class="top_le_logo" @click="tab_home()">
                    <img src="@/assets/image/user/logo.png" alt="">
                </div> -->
                <div class="top_le_logo2" @click="tab_home()">
                    <img src="@/assets/image/special/joy.png" alt="">
                </div>
                <div class="top_le_userinput">
                    <input type="text" :placeholder="searchKeyword" @input="setinput($event)" v-model="searchKeyword" @keydown.enter='btn_tourl'>
                    <img src="@/assets/image/user/inputsou.png" alt="" @click="btn_tourl()">
                </div>
            </div>
            <div class="top_ri">
                <div class="top_ri_btn" @click="btn_xiao()">
                    <span v-if="isxiao>0">+{{isxiao>99?'99+':isxiao}}</span> 消息
                </div>
                <!-- <div class="top_ri_btn" @click="btn_kj()">
                    收藏
                </div> -->
                <div class="top_ri_btn top_ri_btns" @click="btn_logout()" >
                    退出登录
                </div>
                <div class="top_ri_userimg" @click="btn_user()">
                    <img :src="userData.avatar" alt="">
                </div>
                <div class="top_ri_tou" @click="tougao()">
                    <img src="@/assets/image/user/shangchuan.png" alt="">
                    投稿
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 防抖
function debounce(func,delay) {
    // console.log('func',func);
        let timer;
        return function () {
            if(timer)
                clearTimeout(timer);
            timer= setTimeout(()=>{
                func.call(this,...arguments);
            },delay);
        }
    }

import { getUnreadNum } from '@/api/notification'    
export default {
  name: 'YueyiWebTop',

  data() {
    return {
      userData:{},
      searchKeyword:'黑神话悟空',
      isxiao:0
    };
  },

  mounted() {
      let datas= localStorage.getItem('userData')
      this.userData=JSON.parse(datas)

      getUnreadNum()
        .then(res => {
          this.isxiao=res.data.data
        })
  },

  methods: {
    // 关键字获取官方题 part
    setinput:debounce(function(event){
        
    },500),
    getuser(){
        this.$api.getUser().then((res) => {
            this.userData=res.data.data
            this.showVideoPath=this.userData.avatar
            localStorage.setItem('userData', JSON.stringify(res.data.data))
        }).catch(() => {

        })
    },
    btn_tourl(){
        sessionStorage.setItem('searchKeyword',this.searchKeyword)
            const routeUrl = this.$router.resolve({ path: '/'});
            window.open(routeUrl.href, "_blank");
    },
    btn_kj(){
      let id = 4
      const routeUrl = this.$router.resolve({ path: '/PersonalSpace',query: { id }});
      window.open(routeUrl.href, "_blank");
    },

    btn_user(){
      let id = 0
      const routeUrl = this.$router.resolve({ path: '/user',query: { id }});
      window.open(routeUrl.href, "_blank");
    },
    tougao(){
      let id = 4
      const routeUrl = this.$router.resolve({ path: '/user',query: { id }});
      window.open(routeUrl.href, "_blank");
    },
    btn_xiao(){
      let id = 2
      const routeUrl = this.$router.resolve({ path: '/user',query: { id }});
      window.open(routeUrl.href, "_blank");
    },
    tab_home(){
      const routeUrl = this.$router.resolve({ path: '/'});
      window.open(routeUrl.href, "_blank");
    },

    btn_logout(){
      // 调用actions中的logout方法来退出登录
      this.$store.dispatch('logout');
      // 清除localStorage中的token
      localStorage.removeItem('token');
      // 跳转到登录页面
      this.$router.push('/');
        // 刷新页面
      // window.location.reload();
      this.token = localStorage.getItem('token');
    },
  },
};
</script>
<style lang="less" scoped>
    .top{
        width: 100%;
        padding: 28px 0;
        .top_t{
        width: 1642px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_le{
            display: flex;
            align-items: center;
            .top_le_logo{
            width: 52px;
            height: 52px;
            margin-right: 10px;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
            }
            .top_le_logo2{
            width: 110px;
            height: 52px;
            margin-right: 10px;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
            }
            .top_le_userinput{
            width: 607px;
            height: 52px;
            background: #F2F3F4;
            border-radius: 28px 28px 28px 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #4B301F;
            position: relative;
            input{
                height: 100%;
                width: 90%;
                background: #F2F3F4;
                border-radius: 28px 28px 28px 28px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #4B301F;
                padding: 24px;
                box-sizing: border-box;
            }
            img{
                width: 21px;
                height: 21px;
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
            }
        }
        .top_ri{
            display: flex;
            align-items: center;
            .top_ri_btn{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #4B301F;
                margin-right: 34px;
                cursor: pointer;
                position: relative;
                span{
                    min-width: 22px;
                    display: block;
                    height: 20px;
                    background: #EA0000;
                    border-radius: 12px 12px 12px 12px;
                    padding: 0 5px;
                    box-sizing: border-box;
                    font-weight: 500;
                    font-size: 10px;
                    color: #FFFFFF;
                    line-height: 20px;
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    text-align: center;
                }
            }
            .top_ri_btns{
            color: #C8AF88;
            }
            .top_ri_userimg{
              width: 52px;
              height: 52px;
              margin-right: 10px;
              border-radius: 50%;
              cursor: pointer;
              img{
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
              }
            }
            .top_ri_tou{
            width: 120px;
            height: 52px;
            background: #F1E1C8;
            border-radius: 28px 28px 28px 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }
            
            }
        }
        }
    }
</style>